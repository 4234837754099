import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type { UkCookiesPageQuery } from '../../../generated/types';
import { HomepageUkTemplate } from '../../components/templates/HomepageUkTemplate';
import { CookieTermsContent } from '../../modules/legal/CookiesTermsContent';

type PpUkCookiesPage = PageProps<UkCookiesPageQuery>;

function UkCookiesPage({ data: { content } }: PpUkCookiesPage): React.ReactElement {
  return (
    <HomepageUkTemplate seo={content?.frontmatter?.seo}>
      <CookieTermsContent content={content} sx={{ paddingY: 4 }} />
    </HomepageUkTemplate>
  );
}

export const query = graphql`
  query UkCookiesPage {
    content: markdownRemark(frontmatter: { layout: { eq: "cookie" }, locale: { eq: "en" } }) {
      rawMarkdownBody
      frontmatter {
        locale
        title
        cookieDescription
        date(formatString: "DD MM YYYY")
        seo {
          title
          description
          image
        }
      }
    }
  }
`;

export default UkCookiesPage;
